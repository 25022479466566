import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {interval, Observable, Subject} from 'rxjs';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Holiday, HolidayFilter} from '../interfaces/holiday';
import {ErrorService} from './error.service';
import {DatePipe} from "@angular/common";
import {format, isAfter, isBefore, isEqual, parseISO} from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class HolidayService implements OnDestroy {

  private holidayCacheFilter: Partial<HolidayFilter> = {
    afterOrEqualDate: parseISO("1900-01-01"),
    beforeOrEqualDate: parseISO("9999-01-01")
  };
  private destroy$ = new Subject<void>();
  private holidaysCache: Holiday[] = [];
  private cacheIntervalStarted = false;

  static holidayApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/holidays';

  constructor(private datePipe: DatePipe,
              private http: HttpClient) {
    this.cacheHolidays();
  }

  cacheHolidays() {
    // refresh every hour, all operations using this.holidaysCache otherwise use getRemoteHolidays() for live direct feed
    if (this.cacheIntervalStarted) {
      return;
    }

    this.getRemoteHolidays(this.holidayCacheFilter)
      .subscribe(holidays => this.holidaysCache = holidays);

    interval(3600000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(ignore => {
        this.getRemoteHolidays(this.holidayCacheFilter)
          .subscribe(holidays => this.holidaysCache = holidays);
      });

    this.cacheIntervalStarted = true;
  }

  getRemoteHolidays(holidayFilter?: Partial<HolidayFilter>): Observable<Holiday[]> {
    let params = new HttpParams();
    if (holidayFilter !== undefined) {
      Object.entries(holidayFilter).forEach(([key, value]) => {
        if (value instanceof Date) {
          params = params.append(key, format(value, "yyyy-MM-dd"));
        } else {
          params = params.append(key, value as string);
        }
      })
    }
    return this.http.get<Holiday[]>(HolidayService.holidayApi + '/', {params})
      .pipe(
        catchError(ErrorService.handleError),
        map(holidays => { // convert string date to object Date
            holidays.forEach(holiday =>
              holiday.date = parseISO(holiday.date as unknown as string));
            return holidays;
          }
        )
      );
  }

  getHolidays(holidayFilter?: Partial<HolidayFilter>): Holiday[] {
    if (holidayFilter?.afterOrEqualDate === undefined || holidayFilter?.beforeOrEqualDate === undefined) {
      return [];
    }

    let result = this.holidaysCache.filter(holiday => isAfter(holiday.date, holidayFilter.afterOrEqualDate as Date | number) &&
      isBefore(holiday.date, holidayFilter.beforeOrEqualDate as Date | number));

    return result;
  }

  isHoliday(date: Date): boolean {
    return this.holidaysCache.findIndex(holiday => isEqual(holiday.date,date)) >= 0;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
